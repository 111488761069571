import React from 'react';
import _ from 'lodash';
import SlickSlider from 'react-slick';

export interface IVideoPlayer {
  play(): void;
  pause(): void;
}

export interface IProductGalleryContext {
  selectedIndex: number;
  registerVideoPlayer(player: IVideoPlayer, index: number): void;
  changeSelected(selectedIndex: number): void;
  initProductGalleryContext(mainMediaSlider: SlickSlider): void;
  mainMediaSlider: SlickSlider;
}

export const ProductGalleryContext = React.createContext({
  selectedIndex: 0,
  registerVideoPlayer: _.noop,
  changeSelected: _.noop,
  initProductGalleryContext: _.noop,
  mainMediaSlider: {} as SlickSlider,
} as IProductGalleryContext);
