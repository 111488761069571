import style from '../MainMedia.scss';
import {ZoomIcon} from '../../../../icons/dist';
import React from 'react';
import {DataHook} from '../MainMedia';
import {getMagicZoomNodeId} from '../MainMediaUtils';

export const ZoomHintBox = ({
  magicZoom,
  size,
  selectedIndex,
}: {
  magicZoom: any;
  size: number;
  selectedIndex: number;
}) => {
  if (magicZoom) {
    magicZoom.start(getMagicZoomNodeId(selectedIndex));
  }
  return (
    <div
      data-hook={DataHook.zoomHintContainer}
      className={style.zoomHintContainer}
      style={{width: `${size}px`, height: `${size}px`}}>
      <ZoomIcon
        size={`${size * 0.65}`}
        data-hook={DataHook.zoomHintIcon}
        className={style.zoomHint}
        viewBox={`0 0 ${size} ${size}`}
      />
    </div>
  );
};
