import * as React from 'react';
import {IMedia} from '../../../../types/productDef';
import classNames from 'classnames';
import style from '../Thumbnails.scss';
import {Play} from '../../../../icons/dist';
import {keyboardEvents} from '../../../../constants';

export enum DataHook {
  thumbnail = 'thumbnail-container',
  button = 'thumbnail-button',
  image = 'thumbnail-image',
  video = 'video-overlay',
}

interface ForwardRefProps {
  forwardedRef: React.RefObject<HTMLButtonElement>;
}

export interface InnerThumbnailProps {
  mediaItem: IMedia;
  index: number;
  isSelected: boolean;
  isVertical: boolean;
  imgUrl: string;

  handleThumbnailClick(index: number): void;
}

export type ThumbnailProps = InnerThumbnailProps & ForwardRefProps;

class Thumbnail extends React.Component<ThumbnailProps> {
  public readonly onThumbnailClick = () => {
    const {index, handleThumbnailClick} = this.props;
    handleThumbnailClick(index);
  };

  public readonly onKeypressThumbnail = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    const {handleThumbnailClick, index} = this.props;

    if (e.keyCode === keyboardEvents.ENTER.keyCode) {
      handleThumbnailClick(index);
    } else if (e.keyCode === keyboardEvents.ARROW_RIGHT.keyCode || e.keyCode === keyboardEvents.ARROW_UP.keyCode) {
      e.preventDefault();
      e.stopPropagation();
      handleThumbnailClick(index + 1);
    } else if (e.keyCode === keyboardEvents.ARROW_LEFT.keyCode || e.keyCode === keyboardEvents.ARROW_DOWN.keyCode) {
      e.preventDefault();
      e.stopPropagation();
      handleThumbnailClick(index - 1);
    }
  };

  public render() {
    const {index, isVertical, isSelected, mediaItem, imgUrl, forwardedRef} = this.props;
    return (
      <div
        data-hook={DataHook.thumbnail}
        className={classNames(style.thumbnail, {[style.isVerticalThumbnail]: isVertical})}
        key={`media-item-thumbnail-${index}`}>
        <button
          ref={forwardedRef}
          data-hook={DataHook.button}
          onClick={this.onThumbnailClick}
          onKeyPress={this.onKeypressThumbnail}
          onKeyDownCapture={this.onKeypressThumbnail}
          className={classNames(style.thumbnailButton, {[style.thumbnailSelected]: isSelected})}>
          {mediaItem.mediaType === 'VIDEO' && (
            <div className={style.videoOverlay} data-hook={DataHook.video}>
              <Play />
            </div>
          )}
          <img data-hook={DataHook.image} src={imgUrl} />
        </button>
      </div>
    );
  }
}

function withRef(Component) {
  class CompWithRef extends React.Component<ThumbnailProps> {
    public render() {
      const {forwardedRef, ...rest} = this.props;
      return <Component forwardedRef={forwardedRef} {...rest} />;
    }
  }

  return React.forwardRef((props: InnerThumbnailProps, ref: React.RefObject<HTMLButtonElement>) => {
    return <CompWithRef {...props} forwardedRef={ref} />;
  });
}

export const ThumbnailWithRef = withRef(Thumbnail);
