import _ from 'lodash';
import imageSdk from 'image-client-api/dist/imageClientSDK';
import {ImageMode, ImageRatioList} from '../constants';
import {IMedia, IImageDimension, IImageRatioItem} from '../types/productDef';
import {MediaType} from '@wix/wixstores-client-core/dist/src/types/media-item';

const SSR_IMAGE_CONFIG = {
  clientSdkOptions: {
    filters: {
      blur: 100,
    },
    quality: 50,
  },
};

function getMediaRatioByDistance(media: IMedia): IImageRatioItem {
  const mediaRatio = media ? media.height / media.width : 1;

  const distanceArray = calculateDistanceBetweenRatios(mediaRatio);
  const closestRatioByDistanceIndex = getMinValueIndex(distanceArray);
  return ImageRatioList[closestRatioByDistanceIndex];
}

function getImageRatioById(id: number): IImageRatioItem {
  return ImageRatioList.filter((item: IImageRatioItem) => {
    return item.sdkId === id;
  })[0];
}

function calculateDistanceBetweenRatios(imageRatio: number): number[] {
  return ImageRatioList.map((imageRatioItem: IImageRatioItem) => {
    const ratioVal = getImageRatioValue(imageRatioItem);

    return Math.abs(ratioVal - imageRatio);
  });
}

function getMinValueIndex(distanceArray: number[]): number {
  const minValue = Math.min(...distanceArray);
  return distanceArray.indexOf(minValue);
}

function getImageRatioValue(imageRatioItem: IImageRatioItem): number {
  return imageRatioItem.ratio.height / imageRatioItem.ratio.width;
}

function limitDimensions(width, height) {
  const MAX_IMAGE_DIMENSION = 5000;
  const divider = Math.max(1, width / MAX_IMAGE_DIMENSION, height / MAX_IMAGE_DIMENSION);
  return {
    width: width / divider,
    height: height / divider,
  };
}

function isValidMedia(media: IMedia) {
  return (
    media !== null &&
    typeof media === 'object' &&
    [MediaType.PHOTO, MediaType.VIDEO].includes(media.mediaType as MediaType) &&
    typeof media.url === 'string'
  );
}

export function adjustImageDimensionToContainer(
  media: IMedia,
  container: IImageDimension,
  imageMode: ImageMode,
  options: {upsclae?: boolean} = {}
): IImageDimension {
  const getFactor = options.upsclae ? Math.max : Math.min;
  if (imageMode === ImageMode.FIT) {
    const minMultiplier = getFactor(1, container.width / media.width, container.height / media.height);
    return {
      width: media.width * minMultiplier,
      height: media.height * minMultiplier,
    };
  }
  return container;
}

export function getMainImageRatio(media: IMedia, imageRatioId: number): IImageRatioItem {
  if (_.isNumber(imageRatioId) && imageRatioId > -1) {
    return getImageRatioById(imageRatioId);
  } else {
    return getMediaRatioByDistance(media);
  }
}

export function getFullSizedMediaUrl(media: IMedia) {
  const {width, height} = media;
  return getMediaUrl(media, {width, height});
}

export function getMediaUrl(
  media: IMedia,
  {width, height},
  {isSSR, imageMode}: {isSSR?: boolean; imageMode?: ImageMode} = {
    isSSR: false,
    imageMode: null,
  }
) {
  if (!isValidMedia(media) || !width || !height) {
    return '';
  }
  const mediaWidth = Number(media.width);
  const mediaHeight = Number(media.height);
  const {width: newWidth, height: newHeight} = limitDimensions(width, height);
  let options: any = {
    quality: 85,
  };
  if (isSSR) {
    options = SSR_IMAGE_CONFIG.clientSdkOptions;
  } else if (imageMode === ImageMode.CROP && (mediaWidth < newWidth || mediaHeight < newHeight)) {
    options.upscaleMethod = 'super';
  }
  const targetWidth = Math.round(newWidth);
  const targetHeight = Math.round(newHeight);
  return imageSdk.getScaleToFillImageURL(media.url, mediaWidth, mediaHeight, targetWidth, targetHeight, options);
}

export function isVideo(mediaItem: IMedia): boolean {
  return mediaItem && mediaItem.mediaType === MediaType.VIDEO;
}
