import React from 'react';
import {IPropsInjectedByViewerScript, TranslationDictionary} from '../types/app-types';
import {translate} from '@wix/wixstores-client-core/dist/src/viewer-script/utils';
import {ISantaProps} from '@wix/native-components-infra/dist/src/types/types';

const PropsContext = React.createContext<ProvidedGlobalProps>(undefined);

export interface ProvidedGlobalProps {
  globals?: GlobalProps;
}

export type GlobalProps = Pick<
  IPropsInjectedByViewerScript & ISantaProps,
  | 'updateLayout'
  | 'appLoadBI'
  | 'dimensions'
  | 'handleAddToCart'
  | 'handleUserInput'
  | 'hasMultipleMedia'
  | 'infoSection'
  | 'isSSR'
  | 'isInteractive'
  | 'loadFonts'
  | 'onFocusTriggered'
  | 'pagePath'
  | 'pagination'
  | 'product'
  | 'productUrl'
  | 'quantityRange'
  | 'selectedVariant'
  | 'shouldFocusAddToCartButton'
  | 'siteUrl'
  | 'socialSharing'
  | 'style'
  | 'texts'
  | 'userInputErrors'
  | 'userInputs'
  | 'validate'
  | 'navigate'
>;

export interface IProvidedTranslationProps {
  t?: TranslationFunction;
}

type TranslationFunction = (string, values?: {}, fallback?: string) => string;

export function withGlobalPropsProvider(Component) {
  //todo:eran: function instead of class
  return class GlobalPropsProvider extends React.PureComponent<IPropsInjectedByViewerScript & ISantaProps> {
    public render() {
      const {
        updateLayout,
        appLoadBI,
        dimensions,
        handleAddToCart,
        handleUserInput,
        hasMultipleMedia,
        infoSection,
        isInteractive,
        isSSR,
        loadFonts,
        navigate,
        onFocusTriggered,
        pagePath,
        pagination,
        product,
        productUrl,
        quantityRange,
        selectedVariant,
        shouldFocusAddToCartButton,
        siteUrl,
        socialSharing,
        style,
        texts,
        userInputErrors,
        userInputs,
        validate,
        ...passThroughProps
      } = this.props;

      const globals = {
        updateLayout,
        appLoadBI,
        dimensions,
        handleAddToCart,
        handleUserInput,
        hasMultipleMedia,
        infoSection,
        isInteractive,
        isSSR,
        loadFonts,
        navigate,
        onFocusTriggered,
        pagePath,
        pagination,
        product,
        productUrl,
        quantityRange,
        selectedVariant,
        shouldFocusAddToCartButton,
        siteUrl,
        socialSharing,
        style,
        texts,
        userInputErrors,
        userInputs,
        validate,
      };

      return (
        <PropsContext.Provider value={{globals}}>
          <Component {...passThroughProps} />
        </PropsContext.Provider>
      );
    }
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  return props => {
    return <PropsContext.Consumer>{globalProps => <Component {...globalProps} {...props} />}</PropsContext.Consumer>;
  };
}

/* Translations */

function getTranslation(translations: TranslationDictionary): TranslationFunction {
  return (translationKey: string, values: {} = {}, fallback: string = '') => {
    if (!translations[translationKey]) {
      return fallback;
    }
    return translate(translations[translationKey], values);
  };
}

export function withTranslations(Component: any): React.FunctionComponent | any {
  return props => {
    return (
      <PropsContext.Consumer>
        {globalProps => <Component {...props} t={getTranslation(globalProps.globals.texts)} />}
      </PropsContext.Consumer>
    );
  };
}
