import React from 'react';

import {Cell} from '../Cell/Cell';
import {Navigation} from '../../Navigation/Navigation';

import {createLayoutConfigWithDefaults, ProductGalleryLayout} from '../../ProductGallery/Layouts/ProductGalleryLayout';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';
import s from './ClassicLayout.scss';
import {Alignment} from '../../../constants';
import {LayoutComponentProps} from '../../../types/app-types';
import {ProductPriceContainer} from '../../ProductPriceContainer/ProductPriceContainer';
import {convertCssValueToConfig} from '../../../commons/utils';

export const ClassicLayout: React.FunctionComponent<LayoutComponentProps> = ({product, settings}) => (
  <article className={s.container}>
    {settings.shouldShowNavigation && <Navigation className={s.navigation} />}
    <main className={s.row}>
      <section className={s.col}>
        <Cell className={s.gallery}>
          <ProductGalleryLayout
            product={product}
            layoutConfig={createLayoutConfigWithDefaults({
              withMediaBorder: true,
              withImageRatio: true,
              align: Alignment.LEFT,
              allowMagicZoom: true,
              dimensions: {
                mainMedia: {
                  widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
                },
                thumbnails: {
                  widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
                  heightConf: {num: 50, unit: 'px'},
                },
              },
            })}
            {...settings}
          />
        </Cell>

        <Cell className={s.description}>
          <ProductDescription description={product.description} />
        </Cell>
      </section>
      <section className={s.col}>
        <Cell>
          <ProductName name={product.name} />
        </Cell>
        {settings.shouldShowSku && (
          <Cell className={s.sku}>
            <ProductSku />
          </Cell>
        )}

        {settings.shouldShowPrice && (
          <Cell className={s.price}>
            <ProductPriceContainer />
          </Cell>
        )}

        <Cell className={s.options}>
          <ProductOptions
            shouldShowAddToCartButton={settings.shouldShowAddToCartButton}
            shouldShowQuantity={settings.shouldShowQuantity}
          />
        </Cell>

        {settings.shouldShowInfoSection && (
          <Cell className={s.info}>
            <InfoSectionLayout />
          </Cell>
        )}

        {settings.shouldShowSocialNetwork && (
          <Cell className={s.social}>
            <ProductSocial />
          </Cell>
        )}
      </section>
    </main>
  </article>
);
