import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';
import {ProductPrice} from './ProductPrice/ProductPrice';
import * as _ from 'lodash';

export type ProductPriceContainerProps = ProvidedGlobalProps;

const priceKeys = ['price', 'formattedPrice', 'comparePrice', 'formattedComparePrice'];

@withGlobalProps
export class ProductPriceContainer extends React.Component<ProductPriceContainerProps> {
  public render(): JSX.Element {
    let prices;
    const {product} = this.props.globals;
    const selectedVariant = this.props.globals.selectedVariant;

    prices = _.pick(selectedVariant || product, priceKeys);

    if (prices.comparePrice) {
      prices = {
        price: prices.comparePrice,
        formattedPrice: prices.formattedComparePrice,
        comparePrice: prices.price,
        formattedComparePrice: prices.formattedPrice,
      };
    }

    return <ProductPrice {...prices} />;
  }
}
