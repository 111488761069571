export interface ISocialNetwork {
  type: string;
  getBasUrl(): string;
  getQueryStringObj(dataObj: ISocialNetworkData): any;
}

export interface ISocialNetworkData {
  url: string;
  title?: string;
  imageUrl?: string;
}

interface FacebookQueryStringObject {
  u: string;
  title: string;
}

interface TwitterQueryStringObject {
  url: string;
  text: string;
}

interface GoogleQueryStringObject {
  url: string;
}

interface PinterestQueryStringObject {
  url: string;
  media: string;
  description: string;
}

interface FancyQueryStringObject {
  ItemURL: string;
  ImageURL: string;
  Title: string;
}

export class FacebookSocialNetwork implements ISocialNetwork {
  public type: string;
  private readonly baseUrl: string;

  constructor() {
    this.type = 'facebook';
    this.baseUrl = 'http://www.facebook.com/sharer.php';
  }

  public getBasUrl(): string {
    return this.baseUrl;
  }

  public getQueryStringObj(dataObj: ISocialNetworkData): FacebookQueryStringObject {
    return {
      u: dataObj.url,
      title: dataObj.title,
    };
  }
}

export class TwitterSocialNetwork implements ISocialNetwork {
  public type: string;
  private readonly baseUrl: string;

  constructor() {
    this.type = 'twitter';
    this.baseUrl = 'https://twitter.com/intent/tweet';
  }

  public getBasUrl(): string {
    return this.baseUrl;
  }

  public getQueryStringObj(dataObj: ISocialNetworkData): TwitterQueryStringObject {
    return {
      url: dataObj.url,
      text: `${dataObj.title}\n`,
    };
  }
}

export class GoogleSocialNetwork implements ISocialNetwork {
  public type: string;
  private readonly baseUrl: string;

  constructor() {
    this.type = 'google';
    this.baseUrl = 'https://plus.google.com/share';
  }

  public getBasUrl(): string {
    return this.baseUrl;
  }

  public getQueryStringObj(dataObj: ISocialNetworkData): GoogleQueryStringObject {
    return {
      url: dataObj.url,
    };
  }
}

export class PinterestSocialNetwork implements ISocialNetwork {
  public type: string;
  private readonly baseUrl: string;

  constructor() {
    this.type = 'pinterest';
    this.baseUrl = 'http://pinterest.com/pin/create/button/';
  }

  public getBasUrl(): string {
    return this.baseUrl;
  }

  public getQueryStringObj(dataObj: ISocialNetworkData): PinterestQueryStringObject {
    return {
      url: dataObj.url,
      media: dataObj.imageUrl,
      description: dataObj.title,
    };
  }
}

export class FancySocialNetwork implements ISocialNetwork {
  public type: string;
  private readonly baseUrl: string;

  constructor() {
    this.type = 'fancy';
    this.baseUrl = 'http://www.fancy.com/fancyit/fancy';
  }

  public getBasUrl(): string {
    return this.baseUrl;
  }

  public getQueryStringObj(dataObj: ISocialNetworkData): FancyQueryStringObject {
    return {
      ItemURL: dataObj.url,
      ImageURL: dataObj.imageUrl,
      Title: dataObj.title,
    };
  }
}

export class SocialShareService {
  private readonly vendor;

  constructor(vendor: ISocialNetwork) {
    this.vendor = vendor;
  }

  public toQueryParams = dataObj => {
    const queryStringObj = this.vendor.getQueryStringObj(dataObj);
    const queryParams = Object.keys(queryStringObj)
      .map(
        prop =>
          `${prop}=${encodeURIComponent(queryStringObj[prop])
            .replace(/%20/g, '+')
            .replace(/\*/g, '%2A')}`
      )
      .join('&');

    return queryParams;
  };

  public getSocialNetworkUrl = (dataObj: ISocialNetworkData): string => {
    const baseUrl = this.vendor.getBasUrl();
    const queryParams = this.toQueryParams(dataObj);

    return `${baseUrl}?${queryParams}`;
  };
}
