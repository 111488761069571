import React from 'react';

import {Cell} from '../Cell/Cell';
import {Navigation, NavigationType} from '../../Navigation/Navigation';
import {
  createLayoutConfigWithDefaults,
  MarginBottom,
  ProductGalleryLayout,
} from '../../ProductGallery/Layouts/ProductGalleryLayout';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';

import s from './MobileLayout.scss';
import {LayoutComponentProps} from '../../../types/app-types';
import {ProductPriceContainer} from '../../ProductPriceContainer/ProductPriceContainer';
import {ErrorTooltipPlacement, GalleryNavigationType} from '../../../constants';

export const MobileLayout: React.FunctionComponent<LayoutComponentProps> = ({product, settings}) => (
  <article className={s.container}>
    <main className={s.row}>
      {settings.shouldShowNavigation && <Navigation className={s.navigation} type={NavigationType.Short} />}
      <header>
        <Cell className={s.name}>
          <ProductName name={product.name} />
        </Cell>
        {settings.shouldShowSku && (
          <Cell className={s.sku}>
            <ProductSku />
          </Cell>
        )}
        {settings.shouldShowPrice && (
          <Cell className={s.price}>
            <ProductPriceContainer />
          </Cell>
        )}
      </header>

      <Cell>
        <ProductGalleryLayout
          product={product}
          layoutConfig={createLayoutConfigWithDefaults({
            marginBottom: MarginBottom.LARGE,
            withImageRatio: true,
            withMediaBorder: true,
            swipeToScroll: true,
          })}
          navigationType={GalleryNavigationType.DOTS}
          {...settings}
        />
      </Cell>

      <Cell className={s.description}>
        <ProductDescription description={product.description} />
      </Cell>

      <Cell className={s.options}>
        <ProductOptions
          errorPlacement={ErrorTooltipPlacement.Bottom}
          shouldShowQuantity={settings.shouldShowQuantity}
          shouldShowAddToCartButton={settings.shouldShowAddToCartButton}
        />
      </Cell>
      {settings.shouldShowInfoSection && (
        <Cell className={s.info}>
          <InfoSectionLayout />
        </Cell>
      )}
      {settings.shouldShowSocialNetwork && (
        <Cell className={s.social}>
          <ProductSocial />
        </Cell>
      )}
    </main>
  </article>
);
