import React from 'react';
import {Breadcrumbs, Breadcrumb} from './Breadcrumbs/Breadcrumbs';
import {
  withGlobalProps,
  ProvidedGlobalProps,
  withTranslations,
  IProvidedTranslationProps,
} from '../../providers/GlobalPropsProvider';
import {PaginationProps, Pagination} from './Pagination/Pagination';
import cx from 'classnames';
import * as s from './Navigation.scss';
import {last} from '../../commons/utils';
import {ArrowLeft} from '../../icons/dist';
import * as _ from 'lodash';

export interface NavigationProps {
  className?: string;
  type?: NavigationType;
}

export const enum NavigationType {
  Short,
  Long,
}

export const enum NavigationDataHooks {
  ShortBreadcrumbs = 'short-breadcrumbs',
}

@withGlobalProps
@withTranslations
export class Navigation extends React.Component<NavigationProps & ProvidedGlobalProps & IProvidedTranslationProps> {
  public static defaultProps = {
    type: NavigationType.Long,
  };

  private navigationProps(): PaginationProps {
    const {
      t,
      globals: {
        navigate,
        pagination: {prev: prevHref, next: nextHref},
      },
    } = this.props;

    return {
      prev: {
        text: t('BREADCRUMBS_PAGINATION_PREVIOUS'),
        href: prevHref,
        onClick: e => {
          e.preventDefault();
          navigate(prevHref, true);
        },
      },
      next: {
        text: t('BREADCRUMBS_PAGINATION_NEXT'),
        href: nextHref,
        onClick: e => {
          e.preventDefault();
          navigate(nextHref, false);
        },
      },
    };
  }

  private renderBack() {
    const {
      globals: {siteUrl, pagePath},
      t,
    } = this.props;

    const href = last(pagePath) ? last(pagePath).url : siteUrl;
    const pageName = last(pagePath) ? last(pagePath).name : t('BREADCRUMBS_HOME');

    return (
      <a href={href} data-hook={NavigationDataHooks.ShortBreadcrumbs}>
        <ArrowLeft /> {t('BREADCRUMBS_BACK_TO_PAGE_LINK', {pageName}, 'Back to Home')}
      </a>
    );
  }

  private renderBreadcrumbs() {
    const {
      globals: {product, siteUrl, pagePath},
      t,
    } = this.props;

    const path: Breadcrumb[] = [
      {name: t('BREADCRUMBS_HOME'), url: siteUrl},
      ...pagePath.map(p => _.pick(p, ['name', 'url'])),
      {name: product.name},
    ];

    return <Breadcrumbs path={path} />;
  }

  private renderPagination() {
    const {next, prev} = this.navigationProps();
    return (next.href || prev.href) && <Pagination next={next} prev={prev} />;
  }

  public render() {
    const {className, type} = this.props;

    return (
      <nav data-hook="navigation" className={cx(className, s.navigation)}>
        <div className={s.breadcrumbs}>
          {type === NavigationType.Short ? this.renderBack() : this.renderBreadcrumbs()}
        </div>
        {type === NavigationType.Long && this.renderPagination()}
      </nav>
    );
  }
}
