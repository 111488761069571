import {ProductPageApp} from './ProductPageApp';
import {bootstrapApp} from '../../commons/bootstrap';
import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {PRODUCT_PAGE_DSN} from '../../constants';

export default {
  component: withSentryErrorBoundary(
    bootstrapApp(ProductPageApp, {cssPath: ['productPage.min.css', 'productPage.stylable.bundle.css']}),
    {
      dsn: PRODUCT_PAGE_DSN,
      config: {environment: 'Native Component'},
    }
  ),
};
