import React from 'react';
import {IProductOptionsItem, IProductOptionSelectionItem} from '../../../types/productDef';
import s from './ProductColor.scss';
import {ColorSampleGroup} from '@wix/wixstores-client-common-components/dist/src/ColorSampleGroup/ColorSampleGroup';

export interface ProductColorsProps {
  allowMultiple?: boolean;
  onSelect?: Function;
  options: IProductOptionsItem;
  error?: JSX.Element;
}

export interface ProductColorsState {
  selected?: IProductOptionSelectionItem[];
}

export class ProductColors extends React.Component<ProductColorsProps, ProductColorsState> {
  public static defaultProps = {
    allowMultiple: false,
  };

  public state = {
    selected: [],
  };

  public readonly onSelect = (selected): void => {
    this.setState({selected});

    if (this.props.onSelect) {
      this.props.onSelect(selected);
    }
  };

  public readonly renderDescription = (): string => {
    const {selected} = this.state;

    if (!selected.length) {
      return null;
    }

    return `: ${selected.map((selection: IProductOptionSelectionItem) => selection.description).join(', ')}`;
  };

  public render() {
    const {
      options: {title, selections},
      allowMultiple,
      error,
    } = this.props;

    return (
      <section>
        <div data-hook="product-colors-title" className={s.description}>
          {title}
          {this.renderDescription()}
        </div>
        <div className={s.actionable}>
          {error || null}
          <ColorSampleGroup
            allowMultiple={allowMultiple}
            onSelect={this.onSelect}
            options={selections}
            selected={this.state.selected}
          />
        </div>
      </section>
    );
  }
}
