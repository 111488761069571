import React from 'react';
import _ from 'lodash';
import style from './MainMedia.scss';
import classNames from 'classnames';
import SlickSlider, {Settings as SlickSettings} from 'react-slick';
import {ImageMode} from '../../../constants';
import {IMedia, IProduct} from '../../../types/productDef';
import {adjustImageDimensionToContainer, isVideo, getMediaUrl} from '../../../commons/mediaService';
import {ProductGalleryContext} from '../context';
import {ProductThumbnail} from '@wix/wixstores-client-common-components/dist/src/ProductThumbnail';
import {withGlobalProps, ProvidedGlobalProps} from '../../../providers/GlobalPropsProvider';
import {dynamicLoadResource, getMagicZoomNodeId} from './MainMediaUtils';
import {ZoomHintBox} from './ZoomHintBox/ZoomHintBox';
import {DimensionsConfig} from '../Layouts/ProductGalleryLayout';
import {convertConfigToNumber, isSantaFullWidthMode} from '../../../commons/utils';
import {ReactPlayable} from 'react-playable/dist/src/components/ReactPlayable';

declare var window: Window & {mzOptions: any; MagicZoom: any; $mjs: any};

export interface MainMediaProps extends ProvidedGlobalProps {
  dots: boolean;
  imageMode: ImageMode;
  product: IProduct;
  swipeToScroll: boolean;
  layoutDimensions: DimensionsConfig;
  allowMagicZoom: boolean;
  withDynamicHeight: boolean;
}

export interface MainMediaState {
  width: number;
  height: number;
  magicZoom: any;
  mountedDOM: boolean;
}

export enum DataHook {
  productPageMediaVideo = 'product-page-media-video',
  zoomHintContainer = 'zoom-hint-container',
  zoomHintIcon = 'zoom-hint-icon',
  mainMediaImageWrapper = 'main-media-image-wrapper',
  mediaItemLink = 'magic-zoom-link',
  productPageMediaItem = 'product-page-media-item',
  productPageMissingMedia = 'product-page-missing-media',
  productPageMediaSlider = 'product-page-media-slider',
  mainMedia = 'main-media',
  dynamicHeightBackgroundImage = 'dynamic-height-background-image',
}

const ValidEmptyImage = () => (
  <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D" width="0" height="0" alt="" />
);

@withGlobalProps
export class MainMedia extends React.Component<MainMediaProps, MainMediaState> {
  public state = {width: 0, height: 0, magicZoom: null, mountedDOM: false};
  /* tslint:disable:prefer-readonly */
  private _divRef: HTMLDivElement;

  public componentDidMount() {
    const {clientHeight, clientWidth} = this._divRef;
    /* istanbul ignore else: magicZoomPlus will be tested in puppeteer */
    if (this.shouldShowMagicZoom() && !this.state.magicZoom) {
      dynamicLoadResource({
        type: 'link',
        linkAttr: 'href',
        ext: 'css',
        onload: _.noop,
      });
      dynamicLoadResource({
        type: 'script',
        linkAttr: 'src',
        ext: 'js',
        onload: /* istanbul ignore next: access window with specific magicZoomPlus functions */ () => {
          window.$mjs(document).jAddEvent('domready', () => {
            setTimeout(() => {
              window.mzOptions = {
                hint: 'off',
                transitionEffect: true,
                autoStart: false,
                expand: 'off',
                zoomPosition: 'inner',
                smoothing: true,
                rightClick: true,
                upscale: true,
                zoomWidth: `${clientWidth}`,
                zoomHeight: `${clientHeight}`,
              };
              this.setState({magicZoom: window.MagicZoom});
            }, 0);
          });
        },
      });
    }
    this.setState({
      height: clientHeight,
      width: clientWidth,
      mountedDOM: true,
    });
  }

  private getSlickSettings(): SlickSettings {
    const {dots, swipeToScroll} = this.props;
    return {
      arrows: false,
      dots,
      draggable: swipeToScroll,
      fade: !swipeToScroll,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 400,
      accessibility: false,
    };
  }

  private shouldShowMagicZoom() {
    const {
      allowMagicZoom,
      globals: {
        style: {
          styleParams: {
            booleans: {productPage_galleryZoom: galleryZoom},
          },
        },
      },
    } = this.props;
    return allowMagicZoom && galleryZoom;
  }

  private renderVideoItem(media: IMedia, index: number, onAddVideo: (player: any, index: number) => any): JSX.Element {
    const {imageMode} = this.props;

    const {dots} = this.getSlickSettings();
    const video = media.videoFiles[0];
    if (!video) {
      return this.renderImageItem(media, index);
    }
    const WIXVIDEO_BASEURL = 'https://video.wixstatic.com/';
    const relativeVideoUrl = video.url;
    const mediaDimensions = adjustImageDimensionToContainer(media, this.getContainerDOMDimensions(), imageMode);
    const posterUrl = this.getMainImageUrl(media, mediaDimensions, imageMode);
    return (
      <div key={`media-${index}`}>
        <div
          data-hook={DataHook.productPageMediaVideo}
          className={classNames([style.media, style.mediaVideo, {[style.hasDots]: dots}])}>
          <ReactPlayable
            onInit={(player: any) => onAddVideo(player, index)}
            poster={posterUrl}
            width={mediaDimensions.width}
            height={mediaDimensions.height}
            src={`${WIXVIDEO_BASEURL}${relativeVideoUrl}`}
          />
        </div>
      </div>
    );
  }

  private getContainerDOMDimensions(): {width: number; height: number} {
    if (this.state.mountedDOM) {
      return _.pick(this.state, 'width', 'height');
    }
    const {
      layoutDimensions,
      globals: {
        dimensions: {height, width},
      },
    } = this.props;
    const {widthConf, heightConf} = layoutDimensions;
    if (isSantaFullWidthMode(this.props)) {
      widthConf.num = 980;
      widthConf.unit = 'px';
    }
    return {
      width: widthConf ? convertConfigToNumber(widthConf, width) : 0,
      height: heightConf ? convertConfigToNumber(heightConf, height) : 0,
    };
  }

  /* istanbul ignore next: will be checked by puppeteer */
  private getMainImageUrl(
    media: IMedia,
    targetDimensions: {width: number; height: number},
    imageMode: ImageMode
  ): string {
    const {mountedDOM} = this.state;
    return getMediaUrl(media, targetDimensions || media, {
      isSSR: !mountedDOM,
      imageMode,
    });
  }

  private getZoomMainImageUrl(media: IMedia, container: {width: number; height: number}, imageMode: ImageMode): string {
    const target = adjustImageDimensionToContainer(media, container, imageMode, {upsclae: true});
    const bigger = _.mapValues(target, v => v * 2);
    return getMediaUrl(media, bigger, {
      isSSR: false,
      imageMode,
    });
  }

  private forceDimensionsIfNeeded(imageTargetDimensions) {
    if (this.state.mountedDOM) {
      return {};
    }
    const fullWidth = isSantaFullWidthMode(this.props);
    return {width: fullWidth ? '100vw' : imageTargetDimensions.width, height: imageTargetDimensions.height};
  }

  private isDynamicHeight() {
    const {withDynamicHeight} = this.props;
    return !this.state.mountedDOM && withDynamicHeight;
  }

  private renderImage(media: IMedia) {
    const {
      product: {name},
      imageMode,
    } = this.props;
    if (this.isDynamicHeight()) {
      return (
        <div
          data-hook={DataHook.dynamicHeightBackgroundImage}
          style={{
            backgroundImage: `url(${this.getMainImageUrl(media, null, imageMode)})`,
            height: '100%',
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: imageMode === ImageMode.CROP ? 'cover' : 'fit',
          }}
        />
      );
    }
    const mediaClasses = classNames([
      style.media,
      {
        [style.mediaWithZoom]: this.shouldShowMagicZoom(),
        [style.imageModeFit]: imageMode === ImageMode.FIT,
        [style.imageModeCrop]: imageMode === ImageMode.CROP,
      },
    ]);
    const containerDomDimensions = this.getContainerDOMDimensions();
    const mediaDimensions = adjustImageDimensionToContainer(media, containerDomDimensions, imageMode);
    return (
      <img
        data-hook={DataHook.productPageMediaItem}
        className={mediaClasses}
        style={this.forceDimensionsIfNeeded(mediaDimensions)}
        src={this.getMainImageUrl(media, mediaDimensions, imageMode)}
        alt={media.altText || name}
      />
    );
  }

  private renderImageItem(media: IMedia, index: number, {empty} = {empty: false}): JSX.Element {
    const {imageMode} = this.props;
    const containerDomDimensions = this.getContainerDOMDimensions();
    return (
      <div
        data-hook={DataHook.mainMediaImageWrapper}
        className={'main-media-image-wrapper-hook'}
        key={`main-media-image-${index}`}>
        <a
          data-hook={DataHook.mediaItemLink}
          id={getMagicZoomNodeId(index)}
          href={this.getZoomMainImageUrl(media, containerDomDimensions, imageMode)}
          onClick={e => e.preventDefault()}
          className={classNames([style.mediaWrapper, 'media-wrapper-hook'])}
          style={this.isDynamicHeight() ? {width: '100%'} : containerDomDimensions}>
          {empty ? <ValidEmptyImage /> : this.renderImage(media)}
        </a>
      </div>
    );
  }

  private renderItem(item: IMedia, index: number, onVideoRendered: (player: any, index: number) => void): JSX.Element {
    const {mountedDOM} = this.state;
    const renderEmptyImage = index > 0 && !mountedDOM;
    return mountedDOM && isVideo(item)
      ? this.renderVideoItem(item, index, onVideoRendered)
      : this.renderImageItem(item, index, {empty: renderEmptyImage});
  }

  private renderItems(onVideoRendered: (player: any, index: number) => void): JSX.Element | JSX.Element[] {
    const {
      product: {media},
    } = this.props;
    return _.map(media, (item, index) => this.renderItem(item, index, onVideoRendered));
  }

  private renderNoMediaMode(): JSX.Element {
    const {product} = this.props;
    const {height, width} = this.getContainerDOMDimensions();
    return (
      <div
        data-hook={DataHook.productPageMissingMedia}
        className={classNames({
          [style.strechMediaImageWidth]: isSantaFullWidthMode(this.props),
        })}>
        <ProductThumbnail
          width={width}
          height={height}
          product={product}
          defaultImageSize={97}
          getResizedImageUrl={null}
        />
      </div>
    );
  }

  private renderMediaSlider(): JSX.Element {
    const {product} = this.props;
    const shouldShowZoom = this.shouldShowMagicZoom();
    const slickSettings = this.getSlickSettings();
    return (
      <ProductGalleryContext.Consumer>
        {({initProductGalleryContext, selectedIndex, registerVideoPlayer}) => {
          const selectedMediaItem = product.media[selectedIndex];
          const isSelectedMediaVideo = selectedMediaItem.mediaType === 'VIDEO';
          return (
            <>
              <SlickSlider
                key={`slick-slider-with-height-${this.state.height}`}
                data-hook={DataHook.productPageMediaSlider}
                className={classNames([
                  style.slickSliderHook,
                  'main-media-slick-hook',
                  {['force-full-height']: this.isDynamicHeight()},
                ])}
                ref={slider => initProductGalleryContext(slider)}
                {...slickSettings}>
                {this.renderItems(registerVideoPlayer)}
              </SlickSlider>
              {!isSelectedMediaVideo && shouldShowZoom ? (
                <ZoomHintBox magicZoom={this.state.magicZoom} selectedIndex={selectedIndex} size={35} />
              ) : null}
            </>
          );
        }}
      </ProductGalleryContext.Consumer>
    );
  }

  public render() {
    const {
      product: {media},
    } = this.props;
    return (
      <div data-hook={DataHook.mainMedia} className={style.mainMedia} ref={r => (this._divRef = r)}>
        {_.isEmpty(media) ? this.renderNoMediaMode() : this.renderMediaSlider()}
      </div>
    );
  }
}
