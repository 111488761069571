import React from 'react';
import s from './AddToCartButton.scss';
import classNames from 'classnames';
import {isFunction} from 'util';

export enum DataHook {
  AddToCartButton = 'add-to-cart-button',
}

export interface AddToCartButtonProps {
  disabled: boolean;
  onFocusTriggered: Function;
  isFocused?: boolean;
  onSubmit: Function;
  text: string;
}

export class AddToCartButton extends React.Component<AddToCartButtonProps> {
  private readonly addToCartButtonRef: React.RefObject<HTMLButtonElement> = React.createRef();

  public componentDidMount() {
    if (this.props.isFocused) {
      this.focusAddToCartButton();
    }
  }

  public componentDidUpdate(prevProps: AddToCartButtonProps) {
    if (this.props.isFocused !== prevProps.isFocused) {
      this.focusAddToCartButton();
    }
  }

  private readonly focusAddToCartButton = () => {
    this.addToCartButtonRef.current.focus();
    this.props.onFocusTriggered();
  };

  private readonly handleSubmit = () => {
    const {onSubmit} = this.props;

    if (isFunction(onSubmit)) {
      onSubmit();
    }
  };

  public render() {
    const {disabled, text} = this.props;
    const buttonClasses = classNames(s.addToCartButton, {[s.disabled]: disabled});

    return (
      <button
        className={buttonClasses}
        data-hook={DataHook.AddToCartButton}
        disabled={disabled}
        ref={this.addToCartButtonRef}
        type="submit"
        onClick={this.handleSubmit}>
        {text}
      </button>
    );
  }
}
