import React from 'react';
import _ from 'lodash';
import imagesLoaded from 'imagesloaded';
import style from './ProductGalleryLayout.scss';
import {ProductGallery} from '../Composite/ProductGallery';
import {MainMedia, DataHook as MainMediaDataHook} from '../MainMedia/MainMedia';
import {Thumbnails} from '../Thumbnails/Thumbnails';
import {IProduct} from '../../../types/productDef';
import {
  GalleryNavigationPosition,
  GalleryNavigationType,
  GalleryNavigationLocation,
  Alignment,
  ImageMode,
} from '../../../constants';
import classNames from 'classnames';
import {getMainImageRatio} from '../../../commons/mediaService';
import {withGlobalProps, ProvidedGlobalProps} from '../../../providers/GlobalPropsProvider';
import {DataHook as ThumbnailDataHook} from '../Thumbnails/Thumbnail/Thumbnail';
import md5 from 'md5';

export const enum MarginBottom {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export interface DimensionsConfigUnit {
  num: number;
  unit: 'px' | '%';
}

export interface DimensionsConfig {
  widthConf?: DimensionsConfigUnit;
  heightConf?: DimensionsConfigUnit;
}

export interface Dimensions {
  mainMedia: Partial<DimensionsConfig>;
  thumbnails: Partial<DimensionsConfig>;
}

export interface LayoutConfig {
  withMediaBorder: boolean;
  withImageRatio: boolean;
  marginBottom: MarginBottom;
  align: Alignment;
  swipeToScroll: boolean;
  dimensions: Dimensions;
  allowMagicZoom: boolean;
  withDynamicHeight: boolean;
}

export const createLayoutConfigWithDefaults = (overrides: Partial<LayoutConfig> = {}): LayoutConfig => {
  const widthConf = {num: 0, unit: 'px'};
  const heightConf = {num: 0, unit: 'px'};
  return _.defaults(overrides, {
    withMediaBorder: false,
    withImageRatio: false,
    marginBottom: MarginBottom.MEDIUM,
    align: Alignment.CENTER,
    swipeToScroll: false,
    dimensions: {mainMedia: {widthConf, heightConf}, thumbnails: {widthConf, heightConf}},
    allowMagicZoom: false,
    withDynamicHeight: false,
  });
};

export interface ProductGalleryLayoutProps {
  product: IProduct;
  layoutConfig: LayoutConfig;
  imageMode: ImageMode;
  imageRatioId: number;
  navigationType: GalleryNavigationType;
  navigationPosition: GalleryNavigationPosition;
  navigationLocation: GalleryNavigationLocation;
}

@withGlobalProps
export class ProductGalleryLayout extends React.Component<ProductGalleryLayoutProps & ProvidedGlobalProps> {
  private reportedLoad = false;
  private imagesLoaded = false;

  private requiresMediaNavigation(): boolean {
    const {
      globals: {hasMultipleMedia},
    } = this.props;
    return hasMultipleMedia;
  }

  private isThumbnailsNavigationOfPositions(positions): boolean {
    const {navigationType, navigationPosition} = this.props;
    return (
      this.requiresMediaNavigation() &&
      navigationType === GalleryNavigationType.THUMBNAILS &&
      _.includes(positions, navigationPosition)
    );
  }

  private isHorizontalThumbnailsNavigation(): boolean {
    return this.isThumbnailsNavigationOfPositions([GalleryNavigationPosition.BOTTOM]);
  }

  private isVerticalThumbnailsNavigation(): boolean {
    return this.isThumbnailsNavigationOfPositions([GalleryNavigationPosition.LEFT, GalleryNavigationPosition.RIGHT]);
  }

  private renderThumbnailsNavigation(): JSX.Element {
    const {
      product,
      layoutConfig: {align, dimensions},
      navigationPosition,
      navigationLocation,
    } = this.props;
    const conditionalClasses = {
      [style[`thumbnails-inside-${navigationPosition}-navigation`]]:
        navigationLocation === GalleryNavigationLocation.INSIDE,
    };
    const layoutDimensions = {...dimensions.thumbnails};
    if (navigationPosition === GalleryNavigationPosition.BOTTOM) {
      layoutDimensions.heightConf = {num: 50, unit: 'px'};
    } else {
      layoutDimensions.heightConf = {...dimensions.mainMedia.heightConf};
      layoutDimensions.widthConf = {num: 50, unit: 'px'};
    }
    return (
      <div
        data-hook="thumbnails-container"
        className={classNames([
          'slick-thumbnails-container-hook',
          style.thumbnails,
          style[`thumbnails-position-${navigationPosition}`],
          conditionalClasses,
        ])}>
        <Thumbnails
          media={product.media}
          vertical={this.isVerticalThumbnailsNavigation()}
          align={align}
          layoutDimensions={layoutDimensions}
        />
      </div>
    );
  }

  private getMainMediaContainerClass(): string {
    const {
      layoutConfig: {withMediaBorder},
      navigationPosition,
    } = this.props;
    const withVerticalThumbnailsNavigation = this.isVerticalThumbnailsNavigation();
    return classNames([
      style.mainMediaContainer,
      {
        [style[`main-media-${navigationPosition}-navigation`]]: withVerticalThumbnailsNavigation,
        [style.mainMediaBorder]: withMediaBorder,
      },
    ]);
  }

  private getRootClasses(withImageRatio: boolean): string {
    const {
      layoutConfig: {marginBottom},
    } = this.props;
    const horizontalNavigation = this.isHorizontalThumbnailsNavigation()
      ? [style.horizontalNavgationBottom, style[marginBottom]]
      : [];
    return classNames([
      style.root,
      ...horizontalNavigation,
      {
        [style.rootAdaptive]: !withImageRatio,
      },
    ]);
  }

  private isWithThumbnails(): boolean {
    const {navigationType} = this.props;
    if (navigationType !== GalleryNavigationType.THUMBNAILS) {
      return false;
    }
    return this.requiresMediaNavigation();
  }

  private reportLoad() {
    if (!this.reportedLoad && this.props.globals.isInteractive && this.imagesLoaded) {
      this.props.globals.appLoadBI.loaded();
      this.reportedLoad = true;
    }
  }

  public componentDidMount(): void {
    imagesLoaded(
      document.querySelectorAll(
        `[data-hook="${ThumbnailDataHook.image}"],[data-hook="${ThumbnailDataHook.video}"],[data-hook="${
          MainMediaDataHook.productPageMediaItem
        }"]`
      ),
      () => {
        this.imagesLoaded = true;
        this.reportLoad();
        this.props.globals.updateLayout && this.props.globals.updateLayout();
      }
    );
  }

  public componentDidUpdate(): void {
    if (!this.reportedLoad && this.props.globals.isInteractive) {
      this.reportLoad();
    }
  }

  public render() {
    const {
      product,
      layoutConfig: {withImageRatio, swipeToScroll, dimensions, allowMagicZoom, withDynamicHeight},
      imageRatioId,
      navigationType,
      imageMode,
    } = this.props;
    const withDots = navigationType === GalleryNavigationType.DOTS;
    const withThumbnails = this.isWithThumbnails();
    const keyMedia = product.media[0];
    const layoutDimensions = dimensions.mainMedia;
    if (withImageRatio) {
      const {
        ratio: {width, height},
      } = getMainImageRatio(keyMedia, imageRatioId);
      layoutDimensions.heightConf = {
        num: Math.ceil(layoutDimensions.widthConf.num * (height / width)),
        unit: 'px',
      };
    }

    return (
      <div data-hook="product-gallery-root" className={this.getRootClasses(withImageRatio)}>
        <ProductGallery
          withImageRatio={withImageRatio}
          imageRatioId={imageRatioId}
          media={keyMedia}
          mediaSignature={md5(product.media)}>
          <div data-hook="main-media-container" className={this.getMainMediaContainerClass()}>
            <MainMedia
              imageMode={imageMode}
              dots={withDots}
              swipeToScroll={swipeToScroll}
              product={product}
              layoutDimensions={layoutDimensions}
              allowMagicZoom={allowMagicZoom}
              withDynamicHeight={withDynamicHeight}
            />
          </div>
          {withThumbnails ? this.renderThumbnailsNavigation() : null}
        </ProductGallery>
      </div>
    );
  }
}
