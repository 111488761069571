import React from 'react';
import _ from 'lodash';
import SlickSlider, {Settings as SlickSettings} from 'react-slick';
import classNames from 'classnames';
import style from './Thumbnails.scss';
import {IMedia} from '../../../types/productDef';
import {getMediaUrl} from '../../../commons/mediaService';
import {ProductGalleryContext, IProductGalleryContext} from '../context';
import {Alignment, keyboardEvents} from '../../../constants';
import {NavArrowLeft, NavArrowRight, NavArrowDown, NavArrowUp} from '../../../icons/dist';
import {withGlobalProps, ProvidedGlobalProps} from '../../../providers/GlobalPropsProvider';
import {DimensionsConfig} from '../Layouts/ProductGalleryLayout';
import {convertConfigToNumber, convertCssValueToConfig, isSantaFullWidthMode} from '../../../commons/utils';
import {ThumbnailWithRef} from './Thumbnail/Thumbnail';

export interface IRefButtonWithIndex {
  index: number;
  ref: React.RefObject<HTMLButtonElement>;
}

export interface ThumbnailsProps extends ProvidedGlobalProps {
  media: IMedia[];
  vertical: boolean;
  align: Alignment;
  layoutDimensions: DimensionsConfig;
}

export interface ThumbnailsState {
  width: number;
  height: number;
  mountedDOM: boolean;
  currentActiveThumbnailIndex: number;
}

const THUMBNAIL_ITEM_DIMENSION_PX: number = convertCssValueToConfig(style.sharedStyleVariables_tumbnailsItemSize).num;
const THUMBNAIL_IMAGE_DIMENSION_PX: number = convertCssValueToConfig(style.sharedStyleVariables_tumbnailsSize).num;

const ThumbnailsPropsDefaultProps: Partial<ThumbnailsProps> = {
  align: Alignment.CENTER,
};

export const enum ArrowsDir {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down',
}

const NavigationArrow = ({dir}: {dir: ArrowsDir}) => {
  const p = {size: '20px'};
  switch (dir) {
    case ArrowsDir.UP:
      return <NavArrowUp {...p} />;
    case ArrowsDir.DOWN:
      return <NavArrowDown {...p} />;
    case ArrowsDir.LEFT:
      return <NavArrowLeft {...p} />;
    case ArrowsDir.RIGHT:
      return <NavArrowRight {...p} />;
  }
};

const NavigationControl = ({dir, onNav, vertical}: {dir: ArrowsDir; onNav(): void; vertical: boolean}) => {
  const mainClass = vertical ? style.verticalArrows : style.navigationArrows;
  const onKeypressNavigation = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.keyCode === keyboardEvents.ENTER.keyCode) {
      onNav();
    }
  };

  return (
    <div className={classNames([mainClass, style[dir]])}>
      <button
        data-hook={`navigation-arrows-${dir}-button`}
        className={style.resetButton}
        type="button"
        onClick={() => onNav()}
        onKeyPress={onKeypressNavigation}>
        <NavigationArrow dir={dir} />
      </button>
    </div>
  );
};

const WrapWithNavigation = ({withNavigationArrows, children, onNavigate, vertical}) =>
  withNavigationArrows ? (
    <>
      <NavigationControl
        data-hook="navigation-arrows-left"
        onNav={() => onNavigate(ArrowsDir.LEFT)}
        dir={vertical ? ArrowsDir.UP : ArrowsDir.LEFT}
        vertical={vertical}
      />
      {children}
      <NavigationControl
        data-hook="navigation-arrows-right"
        onNav={() => onNavigate(ArrowsDir.RIGHT)}
        dir={vertical ? ArrowsDir.DOWN : ArrowsDir.RIGHT}
        vertical={vertical}
      />
    </>
  ) : (
    children
  );

@withGlobalProps
export class Thumbnails extends React.Component<ThumbnailsProps, ThumbnailsState> {
  private readonly thumbnailsRefArray: IRefButtonWithIndex[] = [];
  /* tslint:disable:prefer-readonly */
  private _ref: HTMLDivElement;
  private _slickRef: SlickSlider;
  /* tslint:enable:prefer-readonly */

  constructor(props: ThumbnailsProps) {
    super(props);
    this.state = this.getInitialState(props);
    for (let index = 0; index < this.props.media.length; index++) {
      this.thumbnailsRefArray.push({index, ref: React.createRef<HTMLButtonElement>()});
    }
  }

  private getInitialState(props: ThumbnailsProps) {
    const {
      globals: {
        dimensions: {height, width},
      },
      layoutDimensions: {widthConf, heightConf},
    } = props;
    const state: ThumbnailsState = {
      width: widthConf ? convertConfigToNumber(widthConf, width) : 0,
      height: heightConf ? convertConfigToNumber(heightConf, height) : 0,
      mountedDOM: false,
      currentActiveThumbnailIndex: 0,
    };
    return state;
  }

  private readonly getRefFromThumbnailsRefArray = (index: number) => {
    return this.thumbnailsRefArray.find(refWithIndexObj => refWithIndexObj.index === index).ref;
  };

  public getSlidesToShow(): number {
    const {media} = this.props;
    const space = this.getNavigationSpace() - 50;
    return Math.min(Math.floor(space / THUMBNAIL_ITEM_DIMENSION_PX), media.length);
  }

  public getSlickSettings(): SlickSettings {
    const {vertical} = this.props;
    const slidesToShow = this.getSlidesToShow();
    return {
      arrows: false,
      dots: false,
      infinite: false,
      slidesToShow,
      slidesToScroll: 1,
      speed: 400,
      accessibility: false,
      swipe: false,
      focusOnSelect: true,
      vertical,
    };
  }

  public renderMediaItem(
    mediaItem: IMedia,
    index: number,
    isSelected: boolean,
    onThumbnailClick: (index: number) => void
  ): JSX.Element {
    const {vertical} = this.props;
    const dimensions = {
      width: THUMBNAIL_IMAGE_DIMENSION_PX,
      height: THUMBNAIL_IMAGE_DIMENSION_PX,
    };
    const imgUrl = getMediaUrl(mediaItem, dimensions, {isSSR: !this.state.mountedDOM});
    return (
      <ThumbnailWithRef
        ref={this.getRefFromThumbnailsRefArray(index)}
        key={index}
        index={index}
        handleThumbnailClick={onThumbnailClick}
        imgUrl={imgUrl}
        isSelected={isSelected}
        isVertical={vertical}
        mediaItem={mediaItem}
      />
    );
  }

  private readonly getNextThumbnailIndex = targetIndex => {
    const {media} = this.props;
    let nextIndex = targetIndex;
    if (targetIndex < 0) {
      nextIndex = media.length - 1;
    } else if (targetIndex === media.length) {
      nextIndex = 0;
    }
    return nextIndex;
  };

  private smartSlickGoTo(target: number) {
    const {media} = this.props;
    const slidesToShow = this.getSlidesToShow();
    const lastIndexToNavigate = media.length - slidesToShow;
    const next = _.inRange(target, lastIndexToNavigate, media.length) ? lastIndexToNavigate : target;
    this._slickRef.slickGoTo(next);
    this.getRefFromThumbnailsRefArray(target);
    const currentThumbnail = this.getRefFromThumbnailsRefArray(target);
    currentThumbnail.current.focus();
    this.setState({currentActiveThumbnailIndex: target});
  }

  private getNavigationData(
    target: ArrowsDir | number,
    currentIndex: number
  ): {slickPolicy: 'goTo' | 'next' | 'prev'; nextIndex: number} {
    const {media} = this.props;
    if (_.isNumber(target)) {
      return {nextIndex: target, slickPolicy: 'goTo'};
    }
    const op = target === (ArrowsDir.LEFT || ArrowsDir.UP) ? _.subtract : _.add;
    const nextIndex = ((op(currentIndex, 1) % media.length) + media.length) % media.length;
    let slickPolicy;
    if (nextIndex === 0 || nextIndex === media.length - 1) {
      slickPolicy = 'goTo';
    } else if (nextIndex > currentIndex) {
      slickPolicy = 'next';
    } else {
      slickPolicy = 'prev';
    }
    return {nextIndex, slickPolicy};
  }

  public renderThumbnails(
    {selectedIndex, changeSelected}: IProductGalleryContext,
    withNavigationArrows: boolean
  ): JSX.Element {
    const {media, vertical} = this.props;
    const onNavigate = (target: ArrowsDir | number) => {
      const newTarget = this.getNextThumbnailIndex(target);
      const {nextIndex, slickPolicy} = this.getNavigationData(newTarget, selectedIndex);
      if (slickPolicy === 'goTo') {
        this.smartSlickGoTo(nextIndex);
      } else if (slickPolicy === 'next') {
        this._slickRef.slickNext();
        const currentActiveThumbnailIndex = this.getNextThumbnailIndex(this.state.currentActiveThumbnailIndex + 1);
        const currentThumbnail = this.getRefFromThumbnailsRefArray(currentActiveThumbnailIndex);
        currentThumbnail.current.focus();
        this.setState({currentActiveThumbnailIndex});
      } else if (slickPolicy === 'prev') {
        this._slickRef.slickPrev();
        const currentActiveThumbnailIndex = this.getNextThumbnailIndex(this.state.currentActiveThumbnailIndex - 1);
        const currentThumbnail = this.getRefFromThumbnailsRefArray(currentActiveThumbnailIndex);
        this.setState({currentActiveThumbnailIndex});
        currentThumbnail.current.focus();
      }
      changeSelected(nextIndex);
    };
    return (
      <WrapWithNavigation withNavigationArrows={withNavigationArrows} vertical={vertical} onNavigate={onNavigate}>
        <SlickSlider
          data-hook="thumbnails-slick"
          {...this.getSlickSettings()}
          className={
            withNavigationArrows &&
            (vertical ? style.carouselWithVerticalNavigationArrows : style.carouselWithNavigationArrows)
          }
          ref={slider => (this._slickRef = slider)}>
          {_.map(media, (item, i) => {
            const isSelected = i === selectedIndex;
            const onThumbnailClick = (index: number) => {
              onNavigate(index);
            };
            return this.renderMediaItem(item, i, isSelected, onThumbnailClick);
          })}
        </SlickSlider>
      </WrapWithNavigation>
    );
  }

  private getNavigationSpace(): number {
    const {vertical} = this.props;
    const {width, height} = this.state;
    return isSantaFullWidthMode(this.props) ? Math.min(980, width) : vertical ? height : width;
  }

  private isWithNavigationArrows() {
    const {media} = this.props;
    const space = this.getNavigationSpace();
    return space / THUMBNAIL_ITEM_DIMENSION_PX < media.length;
  }

  public componentDidMount() {
    const {clientHeight, clientWidth} = this._ref.parentElement;
    this.setState({
      height: clientHeight,
      width: clientWidth,
      mountedDOM: true,
    });
  }

  public render() {
    const {media, vertical, align} = this.props;
    const {width, height} = this.state;
    const withNavigationArrows = this.isWithNavigationArrows();
    const dynamicDimension = vertical
      ? {
          height: `${Math.min(media.length * THUMBNAIL_ITEM_DIMENSION_PX, height)}px`,
        }
      : {
          width: `${Math.min(
            media.length * THUMBNAIL_ITEM_DIMENSION_PX,
            isSantaFullWidthMode(this.props) ? this.getNavigationSpace() : width
          )}px`,
        };
    return (
      <div
        data-hook="thumbnails"
        ref={r => (this._ref = r)}
        className={classNames([
          style.root,
          vertical ? style.vertical : '',
          align === Alignment.CENTER ? style.alignCenter : '',
          withNavigationArrows && (vertical ? style.withVerticalNavigationArrows : style.withNavigationArrows),
        ])}
        style={dynamicDimension}>
        <ProductGalleryContext.Consumer>
          {context => this.renderThumbnails(context, withNavigationArrows)}
        </ProductGalleryContext.Consumer>
      </div>
    );
  }

  public static defaultProps = ThumbnailsPropsDefaultProps;
}
