import React from 'react';
import {
  withGlobalProps,
  ProvidedGlobalProps,
  withTranslations,
  IProvidedTranslationProps,
} from '../../providers/GlobalPropsProvider';
import s from './ProductDescription.scss';

export enum DataHook {
  DescriptionWrapper = 'description-wrapper',
  ContentWrapper = 'content-wrapper',
  Description = 'description',
  ShowMoreOrLess = 'show-more-or-less',
}

interface ProductDescriptionState {
  lineHeight: number;
  height: number;
  isCollapsedStatus: boolean;
}
export interface ProductDescriptionProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  description: string;
}

@withGlobalProps
@withTranslations
export class ProductDescription extends React.Component<ProductDescriptionProps, ProductDescriptionState> {
  private readonly preRef: React.RefObject<HTMLPreElement>;

  constructor(props) {
    super(props);
    this.state = {
      isCollapsedStatus: true,
      height: 0,
      lineHeight: 16,
    };
    this.preRef = React.createRef();
  }

  public async componentDidMount() {
    const {description} = this.props;
    const {loadFonts} = this.props.globals;
    await loadFonts([this.getFont()]);
    if (description) {
      this.setState({
        height: this.preRef.current.clientHeight,
        lineHeight: parseFloat(
          window
            .getComputedStyle(this.preRef.current)
            .getPropertyValue('line-height')
            .replace('px', '')
        ),
      });
    }
  }

  private readonly isShowMoreOrLess = (): boolean => {
    return this.props.globals.style.styleParams.numbers.productPage_descriptionVisibleLineNumbers > 2;
  };

  private readonly getFont = (): string => {
    const {style} = this.props.globals;
    return style.styleParams.fonts.productPage_descriptionFontStyle
      ? style.styleParams.fonts.productPage_descriptionFontStyle.family
      : style.siteTextPresets['Body-M'].fontFamily;
  };

  public isCollapsed(): boolean {
    return this.state.isCollapsedStatus && this.isShowMoreOrLess();
  }

  public toggleDescription(): void {
    const {isCollapsedStatus} = this.state;
    this.setState({isCollapsedStatus: !isCollapsedStatus});
  }

  public isNeedToCollapse(): boolean {
    return this.state.height > this.getCollapsedContentHeight();
  }

  private getCollapsedContentHeight(): number {
    const {productPage_descriptionVisibleLineNumbers} = this.props.globals.style.styleParams.numbers;
    const {lineHeight} = this.state;
    return lineHeight * productPage_descriptionVisibleLineNumbers;
  }

  public getContentHeightStyle(): string {
    if (this.isCollapsed()) {
      return `${this.getCollapsedContentHeight()}px`;
    } else {
      return '';
    }
  }

  private readonly getShowMoreOrLess = () => {
    const {t} = this.props;
    return (
      this.isShowMoreOrLess() &&
      this.isNeedToCollapse() && (
        <button
          aria-hidden="true"
          className={s.showMoreOrLess}
          onClick={() => this.toggleDescription()}
          data-hook={DataHook.ShowMoreOrLess}>
          {this.isCollapsed() ? t('DESCRIPTION_SHOW_MORE') : t('DESCRIPTION_SHOW_LESS')}
        </button>
      )
    );
  };

  public render() {
    const {description} = this.props;
    return !description ? null : (
      <section data-hook={DataHook.DescriptionWrapper} className={s.description}>
        <div className={s.descriptionWrapper}>
          <div
            className={s.contentWrapper}
            data-hook={DataHook.ContentWrapper}
            style={{maxHeight: this.getContentHeightStyle()}}>
            <pre
              ref={this.preRef}
              data-hook={DataHook.Description}
              className={s.content}
              dangerouslySetInnerHTML={{__html: description}}
            />
          </div>
          {this.getShowMoreOrLess()}
        </div>
      </section>
    );
  }
}
